import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    overflowX: 'hidden',
    height: '25vh',
    width: '100%',
    position: 'relative',
    marginBottom:40,
    boxShadow: '0px 5px 15px #00000050',
    '&:hover': {
      boxShadow: 'none',

    },
    '&:hover $slider': { // توقف الحركة عند تمرير المؤشر فوق القطاع
      animationPlayState: 'paused',
    },
  },
  slider: {
    display: 'flex',
    flexDirection: 'row',
    animation: '$slide 120s linear infinite',
  },
  image: {
    marginRight: '5px',
    marginLeft: '5px',
    height: '100%',
    objectFit: 'cover',
    flexShrink: 0,
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textAlign: 'center',
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
    },
    '&:hover $slider': {
      animationPlayState: 'paused',
    },
  },
  '@keyframes slide': {
    '0%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-470%)' },
    
  },
});

const GsSlider = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios('https://api.osoulworld.com/api/v1/files/files-list/?page=1');
      setData(result.data.results);
    };
    fetchData();
  }, []);

  const handleClick = () => {
    window.open('https://gs.osoulworld.com', '_blank');
  };

  return (

    <>
       
                <Box className={classes.root}  >
  <Box className={classes.slider} >
    {data.map((file, index) => (
      <Box key={index} className={classes.image} style={{ position: 'relative' }}>
        <img src={file.thumbnail} alt={file.title} style={{height: '100%', width: 'auto'}}/>
        <Typography variant='caption' style={{
          position: 'absolute',
          bottom: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          maxHeight: '2em',
          lineHeight: '2em',
          direction: 'rtl' ,
        }}>
          {file.title}
        </Typography>
      </Box>
    ))}
  </Box>
  <Box className={classes.overlay} onClick={handleClick}>
    <Typography variant='h4'>انتقل الى اصول جرافيك ستوك</Typography>
  </Box>

</Box>

    </>
  );
};

export default GsSlider;
