import * as React from 'react';

import { useRef, useState, useEffect } from 'react';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui

import { alpha , styled } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton , Tooltip } from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Badge from '@mui/material/Badge';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import IsvaledToken from '../../IsvaledToken';

import { logout } from '../../redux/actions/authActions';
// import FilterBtn from '../../components/Btns/FilterBtn';

// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home Page',
    icon: 'eva:home-fill',
    linkTo: '/',
  },

];

// ----------------------------------------------------------------------

const MENU_OPTIONS2 = [
  {
    label: 'Home Page',
    icon: 'eva:home-fill',
    linkTo: '/advertising-agency',
  },
  {
    label: 'Create account',
    icon: 'eva:home-fill',
    linkTo: '/register',
  },

];




const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {

    right: 0,
    top: 24,
    
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [userData, setUserData] = useState({});
  const [userOrders, setUserOrders] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };
  // -----------------------------------
  // Data after sending the token Validation__Authorization
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  //  getting sub_User_Data Function
  const getUserData = async () => {
    if (userInfo) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo.id}`,
        config
      );
      setUserData(response.data);
    } else {
      console.log("you arnot loged");
    }
  };
  useEffect(() => {
    getUserData();
  },[]);


  //  getting sub_User_Orders Function
  const getUserOrders = async () => {
    if (userInfo) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/Orders/user-orders-list/`,
        config
      );
       setUserOrders(response.data);
      
    } else {
      console.log("you arnot loged");
    }
  };
  useEffect(() => {
    getUserOrders();
  },[]);


  console.log(userOrders.count)  


  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }}

    const labelText = `لديك: ${userOrders.count} طلب في قوائم عروض اسعارك`;
    
    
  return (
    <>
   
     

      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={userInfo ? `${process.env.REACT_APP_API_KEY}${userInfo?.avatarUrl}` : account.photoURL}
          alt="photoURL"
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userInfo ? <>{userInfo.name}</> : 'Unknown vistor'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userInfo ? <>{userInfo.email}</> : 'Signin and enjoy'}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />


        {userInfo ? (
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} href={option.linkTo}  onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
):(

  <Stack sx={{ p: 1 }}>
  {MENU_OPTIONS2.map((option) => (
    <MenuItem key={option.label}  href={option.linkTo} onClick={handleClose}>
      {option.label } 
    </MenuItem>
  ))}
</Stack>

)}




        <Divider sx={{ borderStyle: 'dashed' }} />

        {userInfo ? (
          <MenuItem onClick={logoutHandler} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        ) : (
          <MenuItem to="/login" component={RouterLink} sx={{ m: 1 }}>
            Login
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
}
