import { Navigate, useRoutes } from 'react-router-dom';

import Home from '../pages/mainpages/Home';
import MainLayout from '../layouts/main';



export default function Router() {




  return useRoutes([

    
    
    
    

    {
      path: '/',
      element: <MainLayout />,

      children: [
        { path: '', element: <Home /> },
      ],
    },
    
    { path: '*', element: <Navigate to="/" replace /> },
  ]);
}
