// scroll bar
import 'simplebar/src/simplebar.css';
import React from "react";
import * as ReactDOMClient from 'react-dom/client';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// ** Redux Imports
import { Provider } from 'react-redux';
import store from './redux/store';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// ----------------------------------------------------------------------
const root = ReactDOMClient.createRoot(document.getElementById("root"));


root.render(

  <HelmetProvider>
    <BrowserRouter>
    <React.StrictMode>

        <Provider store={store}>
            <App />
        </Provider>
        </React.StrictMode>

    </BrowserRouter>
  </HelmetProvider>,

);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

