import * as React from 'react';

// components
import Box from '@mui/material/Box';
import { Stack , Typography } from '@mui/material';
import Button from '@mui/material/Button';

import img2 from './Advertising_agency.png'

import Page from '../../components/Page';
import GsSlider from '../../components/sliders/GsSlider';
import AdvSlider from '../../components/sliders/AdvSlider';



export default function Home() {



  return (
    <Page title="الرئيــسية"  >
          <Stack direction={{xl:"row", lg:"row", md:"row", sm:"row", xs:"column"}} justifyContent="space-between"  sx={{ direction:'rtl' , maxHeight:"20%" , boxShadow:'rgba(0, 0, 0, .15) 0 2px 5px 0', borderRadius:'5px'}} >
                        
                        
                            
                        <Stack direction="column" textAlign='center'  display='flex' alignItems='center' justifyContent='center'>
                        <Typography  variant='h4' color="#006673" sx={{ borderBottom: 2 , px:3 , borderColor: 'divider' }}>وكالة أصــــول للإعلان </Typography>

                        <Typography variant='h6' gutterBottom >نساعدك في رسم وتصميم الهوية البصرية الخاصة بنشاطك إبتداءً من الشعار أو اللوجو والكارت الشخصي مروراً بـ ديكور المكان وشكل الواجهة وحتي الموقع الخاص بنشاطك وصفحات وقنوات التواصل الاجتماعي
                                                    إبتكار حلول إعلانية وتسويقة وخلق افكار ترويجية إبداعية تساعد نشاطك علي الانتشار وتحقيق أهدافك التسويقية
                                                    تخطيط وإدارة وتنفيذ الحملات الإعلانية بجميع أشكالها " مرئية - مسموعة - مقروءة .</Typography>

                        </Stack>

      </Stack>
        <AdvSlider />

        <Stack direction={{xl:"row", lg:"row", md:"row", sm:"row", xs:"column"}} justifyContent="space-between"  sx={{ direction:'rtl' , maxHeight:"20%" , boxShadow:'rgba(0, 0, 0, .15) 0 2px 5px 0', borderRadius:'5px'}} >

                        <Stack direction="column" textAlign='center'  display='flex' alignItems='center' justifyContent='center'>
                        <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 ,px:3 , borderColor: 'divider' }}>أصـــول جرافيك ستوك </Typography>
                        <Typography variant='h6' gutterBottom  >الالاف من التصميمات الجاهزة والقوالب والادوات والملفات والصور ذات الجودة العالية التي تساعدك في انشاء التصميمات ،، الاف من تصميمات ورق الحائط والتابلوهات ومكتبة من اجمل تصميمات الليز ،، يمكنك تصفحها وتحميلها وتعديلها بكل سهولة ،، قسم مخصص لخدمة العاملين بمجالات الدعاية والاعلان ومصممي الجرافيك .</Typography>
                        </Stack>

      </Stack>
      
      <GsSlider />



    </Page>
  );
}
