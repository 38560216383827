// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// state:'disabled' for disabled link
// state:'block' for visable & clickable link

const navConfig = [


  
  {
    title: 'دعـاية وإعـــلان',
    path: '/advertising-agency/advertising',
    icon: getIcon('eva:layers-outline'),
    state: 'block',
  },
  {
    title: 'تصمـيم جرافيكي',
    path: '/advertising-agency/designing',
    icon: getIcon('eva:briefcase-outline'),
    state: 'block',
  },
  {
    title: 'تسويق الكتروني',
    path: '/advertising-agency/marketing',
    icon: getIcon('eva:image-outline'),
    state: 'block',
  },
  {
    title: 'طبـاعة وتنفــــيذ',
    path: '/advertising-agency/printing',
    icon: getIcon('eva:speaker-outline'),
    state: 'block',
  },


 

  // {
  //   title: 'Graphic artists',
  //   path: '#',
  //   icon: getIcon('eva:color-palette-outline'),
  //   state: 'block',
  // },
];

export default navConfig;
