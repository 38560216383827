// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// state:'disabled' for disabled link
// state:'block' for visable & clickable link

const navConfig = [
  
  {
    title: 'graphic stock',
    path: 'https://gs.osoulworld.com/Graphic-Stock',
    icon: getIcon('eva:arrowhead-right-outline'),
    state: 'block',
  },


  {
    title: 'advertising agency',
    path: 'https://adv.osoulworld.com/advertising-agency',
    icon: getIcon('eva:arrowhead-right-outline'),
    state: 'block',
  },

  // {
  //   title: 'Graphic artists',
  //   path: '#',
  //   icon: getIcon('eva:color-palette-outline'),
  //   state: 'block',
  // },
];

export default navConfig;
